import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Alert, Row, Col, Spinner, Container } from 'react-bootstrap';
import { fetchAuthSession, updateUserAttributes, updatePassword } from 'aws-amplify/auth';
import { AppContext } from '../../AppContext';
import { User, Phone, Mail, Key, Loader, Edit, Info } from 'lucide-react';

const ProfilePage = () => {
  const { checkAuthStatus, isDark, authToken } = useContext(AppContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
  });
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState(null);
  const [isFederatedUser, setIsFederatedUser] = useState(false);

  useEffect(() => {
    if (authToken) {
      fetchUserData();
    } else {
      checkAuthStatus().then(() => {
        if (authToken) {
          fetchUserData();
        }
      });
    }
  }, [authToken, checkAuthStatus]);


  const fetchUserData = async () => {
    try {
      setLoading(true);
      setError(null);
  
      if (!authToken) {
        throw new Error('No auth token available');
      }
  
      const session = await fetchAuthSession();
      const idTokenPayload = session.tokens.idToken.payload;
  
      const userAttributes = {
        name: idTokenPayload.name,
        email: idTokenPayload.email,
        phone_number: idTokenPayload.phone_number,
      };
  
      setUser(userAttributes);
      setFormData({
        name: userAttributes.name || '',
        email: userAttributes.email || '',
        phone_number: userAttributes.phone_number ? userAttributes.phone_number.replace('+1', '') : '',
      });
  
      const federated = !!idTokenPayload.identities;
      setIsFederatedUser(federated);
  
    } catch (err) {
      console.error('Failed to fetch user data:', err);
      setError(`Failed to fetch user data: ${err.message}`);
      await checkAuthStatus(); // Re-check auth status in case of failure
    } finally {
      setLoading(false);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone_number') {
      const numericValue = value.replace(/\D/g, '');
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePasswordInputChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    setPasswordError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setError(null);
    setSuccess(null);
    try {
      if (isFederatedUser) {
        setSuccess('Profile information is managed by your Google account.');
      } else {
        const updatedAttributes = {
          name: formData.name,
          phone_number: formData.phone_number ? `+1${formData.phone_number}` : undefined,
        };
        
        await updateUserAttributes({
          userAttributes: updatedAttributes,
        });
        setSuccess('Profile updated successfully!');
      }
      
      setEditMode(false);
      await fetchUserData();
    } catch (err) {
      console.error('Failed to update profile:', err);
      setError(`Failed to update profile: ${err.message}`);
    } finally {
      setUpdating(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setPasswordError(null);
    setSuccess(null);

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError('New passwords do not match');
      setUpdating(false);
      return;
    }

    try {
      await updatePassword({
        oldPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
      });
      setSuccess('Password changed successfully!');
      setPasswordData({ oldPassword: '', newPassword: '', confirmPassword: '' });
    } catch (err) {
      console.error('Failed to change password:', err);
      setPasswordError(`Failed to change password: ${err.message}`);
    } finally {
      setUpdating(false);
    }
  };


  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return 'Not set';
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
    }
    return phoneNumber;
  };

  const LoadingOverlay = () => (
    <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 1000}}>
      <Loader size={40} className="text-primary animate-spin" />
    </div>
  );

  return (
    <div className={`App profile-page ${isDark ? 'dark-mode' : ''}`}>
      <Container className="py-5">
        <h1 className="text-center mb-5" style={{ fontSize: '2.5rem', fontWeight: '700' }}>User Profile</h1>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p style={{ fontSize: '1.1rem' }}>Loading user data...</p>
          </div>
        ) : (
          <div className="position-relative">
            {updating && <LoadingOverlay />}
            <Row>
              <Col md={6}>
                <div className="mb-5">
                  <h2 className="h4 mb-4" style={{ fontSize: '1.5rem', fontWeight: '600' }}>Profile Information</h2>
                  {!editMode ? (
                    <div className="mb-4">
                      <p className="d-flex align-items-center mb-3" style={{ fontSize: '1.1rem' }}>
                        <User size={20} className="me-3" />
                        <span><strong>Name:</strong> {user?.name || 'Not set'}</span>
                      </p>
                      <p className="d-flex align-items-center mb-3" style={{ fontSize: '1.1rem' }}>
                        <Mail size={20} className="me-3" />
                        <span><strong>Email:</strong> {user?.email || 'Not available'}</span>
                      </p>
                      {!isFederatedUser && (
                        <>
                          <p className="d-flex align-items-center mb-3" style={{ fontSize: '1.1rem' }}>
                            <Phone size={20} className="me-3" />
                            <span><strong>Phone:</strong> {formatPhoneNumber(user?.phone_number)}</span>
                          </p>
                          <Button variant="outline-primary" onClick={() => setEditMode(true)} style={{ fontSize: '1.1rem', padding: '0.5rem 1rem' }}>
                            Edit Profile <Edit size={18} className="ms-2" />
                          </Button>
                        </>
                      )}
                    </div>
                  ) : (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontSize: '1.1rem' }}>Name</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="name" 
                          value={formData.name} 
                          onChange={handleInputChange}
                          style={{ fontSize: '1.1rem', padding: '0.5rem' }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontSize: '1.1rem' }}>Email</Form.Label>
                        <Form.Control 
                          type="email" 
                          value={formData.email} 
                          disabled
                          style={{ fontSize: '1.1rem', padding: '0.5rem' }}
                        />
                      </Form.Group>
                      {!isFederatedUser && (
                        <Form.Group className="mb-3">
                          <Form.Label style={{ fontSize: '1.1rem' }}>Phone</Form.Label>
                          <Form.Control 
                            type="tel" 
                            name="phone_number" 
                            value={formData.phone_number} 
                            onChange={handleInputChange} 
                            placeholder="1234567890"
                            style={{ fontSize: '1.1rem', padding: '0.5rem' }}
                          />
                          <Form.Text className="text-muted" style={{ fontSize: '0.9rem' }}>
                            Enter 10-digit US phone number (e.g., 1234567890). Country code (+1) will be added automatically.
                          </Form.Text>
                        </Form.Group>
                      )}
                      <div className="mt-4">
                        <Button variant="primary" type="submit" className="me-2" style={{ fontSize: '1.1rem', padding: '0.5rem 1rem' }}>Save Changes</Button>
                        <Button variant="outline-secondary" onClick={() => setEditMode(false)} style={{ fontSize: '1.1rem', padding: '0.5rem 1rem' }}>Cancel</Button>
                      </div>
                    </Form>
                  )}
                </div>
              </Col>
  
              <Col md={6}>
                {!isFederatedUser ? (
                  <div>
                    <h2 className="h4 mb-4" style={{ fontSize: '1.5rem', fontWeight: '600' }}>Change Password</h2>
                    <Form onSubmit={handlePasswordChange}>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontSize: '1.1rem' }}>Old Password</Form.Label>
                        <Form.Control 
                          type="password" 
                          name="oldPassword"
                          value={passwordData.oldPassword}
                          onChange={handlePasswordInputChange}
                          required
                          style={{ fontSize: '1.1rem', padding: '0.5rem' }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontSize: '1.1rem' }}>New Password</Form.Label>
                        <Form.Control 
                          type="password" 
                          name="newPassword"
                          value={passwordData.newPassword}
                          onChange={handlePasswordInputChange}
                          required
                          style={{ fontSize: '1.1rem', padding: '0.5rem' }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label style={{ fontSize: '1.1rem' }}>Confirm New Password</Form.Label>
                        <Form.Control 
                          type="password" 
                          name="confirmPassword"
                          value={passwordData.confirmPassword}
                          onChange={handlePasswordInputChange}
                          required
                          style={{ fontSize: '1.1rem', padding: '0.5rem' }}
                        />
                      </Form.Group>
                      {passwordError && <Alert variant="danger" style={{ fontSize: '1rem' }}>{passwordError}</Alert>}
                      <Button variant="primary" type="submit" disabled={updating} style={{ fontSize: '1.1rem', padding: '0.5rem 1rem' }}>
                        Change Password <Key size={18} className="ms-2" />
                      </Button>
                    </Form>
                  </div>
                ) : (
                  <div>
                    <h2 className="h4 mb-4" style={{ fontSize: '1.5rem', fontWeight: '600' }}>Account Security</h2>
                    <p style={{ fontSize: '1.1rem' }}>Your account is secured through Google. To manage your account security, please visit your Google account settings.</p>
                  </div>
                )}
              </Col>
            </Row>
            {success && <Alert variant="success" className="mt-4" style={{ fontSize: '1rem' }}>{success}</Alert>}
            {error && <Alert variant="danger" className="mt-4" style={{ fontSize: '1rem' }}>{error}</Alert>}
            {isFederatedUser && (
              <div className="mt-5 p-4 border rounded" style={{ backgroundColor: isDark ? '#2c3e50' : '#f8f9fa', borderColor: isDark ? '#34495e' : '#e9ecef' }}>
                <div className="d-flex align-items-start">
                  <Info size={24} className="me-3 mt-1" style={{ color: '#3498db' }} />
                  <div>
                    <h3 style={{ fontSize: '1.3rem', fontWeight: '600', marginBottom: '0.5rem', color: isDark ? '#ecf0f1' : '#2c3e50' }}>Google-Managed Account</h3>
                    <p style={{ fontSize: '1.1rem', marginBottom: '0', color: isDark ? '#bdc3c7' : '#34495e' }}>
                      Your profile information is managed by your Google account. To make any changes to your profile or security settings, please visit your Google account dashboard.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default ProfilePage;