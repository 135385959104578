import React, { useState, useContext } from 'react';
import { signIn, signUp, confirmSignUp, resendSignUpCode, signInWithRedirect } from 'aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Alert, Container, Image } from 'react-bootstrap';
import { UserPlus, Check, Eye, EyeOff, RefreshCw, ArrowLeft } from 'lucide-react';
import { AppContext } from '../../AppContext';

import googleSignInLight from '../../google/branding_guideline_sample_lt_rd_lg.svg';
import googleSignInDark from '../../google/branding_guideline_sample_dk_rd_lg.svg';
import BackgroundCollage from '../../images/collages/Results_Collage.jpg';

function CreateAccount() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [googleButtonState, setGoogleButtonState] = useState('normal');
  const [resendLoading, setResendLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);

  const { isDark, updateAuthStatus, checkAuthStatus } = useContext(AppContext);
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `url(${BackgroundCollage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
  };

  const cardStyle = {
    backgroundColor: isDark ? 'rgba(33, 33, 33, 1)' : 'rgba(255, 255, 255, 1)',
    padding: '2rem 2rem 2.5rem 2rem',
    borderRadius: '20px',
    border: isDark ? '3px solid #ffffff' : '3px solid #000000',
    boxShadow: isDark 
      ? '0 0 20px rgba(255, 255, 255, 0.1)' 
      : '0 0 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '90%',
    margin: '2rem auto',
  };

  const stepBarContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  };

  const stepTextStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    color: isDark ? '#ffffff' : '#000000',
  };

  const progressBarStyle = {
    flex: '1',
    height: '8px',
    backgroundColor: isDark ? '#444' : '#ddd',
    borderRadius: '4px',
    overflow: 'hidden',
  };

  const progressStyle = {
    height: '100%',
    backgroundColor: isDark ? '#ffffff' : '#000000',
    width: `${(step / 4) * 100}%`,
    transition: 'width 0.3s ease-in-out',
  };

  const renderStepBar = () => (
    <div style={stepBarContainerStyle}>
      <div style={stepTextStyle}>Step {step}/4</div>
      <div style={progressBarStyle}>
        <div style={progressStyle}></div>
      </div>
    </div>
  );

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setError('');
    if (email) {
      setStep(2);
    } else {
      setError('Please enter a valid email address');
    }
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
    } else {
      setStep(3);
    }
  };

  const handleConfirmPasswordSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords don't match");
    } else {
      handleSignUp();
    }
  };

  const handleSignUp = async () => {
    setError('');
    setLoading(true);
    try {
      await signUp({ username: email, password });
      setStep(4);
    } catch (err) {
      setError(err.message || 'An error occurred during sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await confirmSignUp({ username: email, confirmationCode });
      
      // Automatically sign in the user
      const signInResult = await signIn({ username: email, password });
      
      if (signInResult.isSignedIn) {
        await checkAuthStatus(); // Update the global auth state
        navigate('/'); // Navigate to home page
      } else {
        throw new Error('Failed to sign in automatically');
      }
    } catch (err) {
      setError(err.message || 'An error occurred during confirmation or sign in');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      setGoogleButtonState('pressed');
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error("Error initiating Google sign up:", error);
      setError("Failed to initiate Google sign up. Please try again.");
      setGoogleButtonState('normal');
    }
  };

  const handleResendCode = async () => {
    setError('');
    setResendLoading(true);
    setResendSuccess(false);
    try {
      await resendSignUpCode({ username: email });
      setResendSuccess(true);
    } catch (err) {
      setError(err.message || 'An error occurred while resending the code');
    } finally {
      setResendLoading(false);
    }
  };

  const getGoogleButtonImage = () => {
    return isDark ? googleSignInDark : googleSignInLight;
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Form onSubmit={handleEmailSubmit}>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              />
            </Form.Group>
            <div className="d-grid gap-3 mt-5">
              <Button 
                variant="primary" 
                type="submit" 
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                Next
                <UserPlus className="ms-2" size={20} />
              </Button>
              <Button 
                onClick={handleGoogleSignUp} 
                variant="link"
                className="p-0"
                onMouseEnter={() => setGoogleButtonState('focus')}
                onMouseLeave={() => setGoogleButtonState('normal')}
                onMouseDown={() => setGoogleButtonState('pressed')}
                onMouseUp={() => setGoogleButtonState('focus')}
                onTouchStart={() => setGoogleButtonState('pressed')}
                onTouchEnd={() => setGoogleButtonState('normal')}
              >
                <Image 
                  src={getGoogleButtonImage()}
                  alt="Sign up with Google"
                  style={{ height: '50px', width: 'auto' }}
                />
              </Button>
              <Link 
                to="/login" 
                className="btn btn-link" 
                style={{ 
                  fontSize: '1.1rem', 
                  textDecoration: 'none', 
                  color: isDark ? '#ffffff' : '#000000' 
                }}
              >
                Already have an account? Log in here
              </Link>
            </div>
          </Form>
        );
      case 2:
        return (
          <Form onSubmit={handlePasswordSubmit}>
            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                />
                <Button
                  variant="link"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </Button>
              </div>
              <div className="mt-2">
                <span style={{ color: password.length >= 8 ? 'green' : 'red' }}>
                  {password.length >= 8 ? <Check size={20} /> : '❌'} At least 8 characters
                </span>
              </div>
            </Form.Group>
            <div className="d-grid gap-3 mt-5">
              <Button 
                variant="primary" 
                type="submit" 
                disabled={loading}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                Next
              </Button>
              <Button 
                variant="outline-secondary" 
                onClick={() => setStep(1)}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                <ArrowLeft size={20} className="me-2" />
                Back
              </Button>
            </div>
          </Form>
        );
      case 3:
        return (
          <Form onSubmit={handleConfirmPasswordSubmit}>
            <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
              <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              />
            </Form.Group>
            <div className="d-grid gap-3 mt-5">
              <Button 
                variant="primary" 
                type="submit" 
                disabled={loading}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                {loading ? 'Processing...' : 'Create Account'}
                <UserPlus className="ms-2" size={20} />
              </Button>
              <Button 
                variant="outline-secondary" 
                onClick={() => setStep(2)}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                <ArrowLeft size={20} className="me-2" />
                Back
              </Button>
            </div>
          </Form>
        );
      case 4:
        return (
          <Form onSubmit={handleConfirmSignUp}>
            <Form.Group className="mb-4" controlId="formConfirmationCode">
              <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Confirmation Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter confirmation code"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              />
            </Form.Group>
            <div className="d-grid gap-3 mt-5">
              <Button 
                variant="primary" 
                type="submit" 
                disabled={loading}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                {loading ? 'Processing...' : 'Confirm Account'}
                <Check className="ms-2" size={20} />
              </Button>
              <Button
                variant="outline-secondary"
                onClick={handleResendCode}
                disabled={resendLoading}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                {resendLoading ? 'Resending...' : 'Resend Code'}
                <RefreshCw className="ms-2" size={20} />
              </Button>
            </div>
            {resendSuccess && (
              <Alert variant="success" className="mt-3">
                A new confirmation code has been sent to your email.
              </Alert>
            )}
          </Form>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`create-account-page ${isDark ? 'dark-mode' : ''}`} style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={backgroundStyle}></div>
      <Container className="h-100 d-flex align-items-center justify-content-center py-5">
        <div style={cardStyle}>
          {renderStepBar()}
          <h1 className="text-center mb-5" style={{ fontSize: '2.5rem', fontWeight: '700', color: isDark ? '#ffffff' : '#000000' }}>
            Create Account
          </h1>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          {renderStep()}
        </div>
      </Container>
    </div>
  );
}

export default CreateAccount;