// App.js

import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Container, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import awsExports from './aws-exports';
import { AppProvider, AppContext } from './AppContext';
import Layout from './components/Layout';
import { Login, CreateAccount, PasswordRecovery } from './components/auth';
import PomuForm from './components/PomuForm';
import SketchForm from './components/SketchForm';
import MainPage from './components/Home';
import ManufacturerResults from './components/manufacture/ManufacturerResults';
import ManufacturerTemplate from './components/manufacture/ManufacturerTemplate';
import ProfilePage from './components/auth/ProfilePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContactForm from './components/ContactForm';
import ManufacturerList from './components/manufacture/ManufacturerList';
import SubmissionHistory from './components/SubmissionHistory.js';
import About from './components/About.js';




Amplify.configure(awsExports);

function AppContent() {
  const { isAuthenticated, isAuthChecking, handleLogout } = useContext(AppContext);

  if (isAuthChecking) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path="/home" element={<MainPage />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout onSignOut={handleLogout} />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/pomuform" element={<PomuForm />} />
        <Route path="/sketch" element={<SketchForm />} />
        <Route path="/manufacturer-results" element={<ManufacturerResults />} />
        <Route path="/manufacturer/:manufacturerId" element={<ManufacturerTemplate />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/contactform" element={<ContactForm />} />
        <Route path="/manufacturers" element={<ManufacturerList />} />
        <Route path="/history" element={<SubmissionHistory />} />
        <Route path="/about" element={<About />} />
        <Route 
          path="/profile" 
          element={
            isAuthenticated ? (
              <ProfilePage />
            ) : (
              <Navigate to="/home" state={{ from: '/profile' }} />
            )
          } 
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </Router>
  );
}

function Logout({ onSignOut }) {
  React.useEffect(() => {
    onSignOut();
  }, [onSignOut]);

  return <Navigate to="/home" />;
}

export default App;