import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col lg={8} className="mx-auto">
          <h1 className="mb-4"> Privacy Policy</h1>
          <p className="text">Last updated: October 2, 2024</p>
          
          <div className="privacy-content mt-4">
            <p>
              This Privacy Policy ("Policy") describes how Pomu ("Company," "we," "our," or "us") collects, uses, and discloses your information when you use our website and services (collectively, the "Services"). By accessing or using our Services, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy.
            </p>

            <p>
              1. Information We Collect: We collect personal information that you provide directly to us, including but not limited to your name and email address. We also collect your phone number, which is optional. Additionally, we collect any content that you choose to upload to our platform, including design files and associated metadata. We automatically collect certain information about your device and how you interact with our Services, such as IP address, browser type, device information, and log data.
            </p>

            <p>
              2. Use of Information: We use the collected information to provide, maintain, and improve our Services; to process and complete transactions; to communicate with you; to send you technical notices, updates, security alerts, and support and administrative messages; and to comply with legal obligations. We may use your uploaded designs to facilitate our services, including matching you with potential manufacturers.
            </p>

            <p>
              3. Sharing of Information: We may share your information with third-party vendors, consultants, and other service providers who need access to such information to carry out work on our behalf. We may also share your information when required by law or to protect our rights and the rights of others.
            </p>

            <p>
              4. Data Retention: We retain personal information for as long as necessary to provide our Services and fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.
            </p>

            <p>
              5. Your Rights: Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, delete, or restrict use of your information. To exercise these rights, please contact us using the information provided at the end of this Policy.
            </p>

            <p>
              6. Security: We implement appropriate technical and organizational measures to protect the security of your personal information. However, please be aware that no security measures are perfect or impenetrable.
            </p>

            <p>
              7. Children's Privacy: Our Services are not directed to children under 13, and we do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13, we will delete that information.
            </p>

            <p>
              8. Prohibited Activities and Use of Information: We strictly prohibit the use of our Services for illegal activities, including the creation or facilitation of counterfeit products. We reserve the right to investigate and address suspected illegal activities. In such cases, we may use and disclose your information to investigate, prevent, or take action regarding illegal activities, including the creation or facilitation of counterfeit products. This may include sharing information with law enforcement, regulatory authorities, or other third parties as necessary to protect our rights, the rights of our users, and the integrity of our Services.
            </p>

            <p>
              9. Changes to This Policy: We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Policy.
            </p>

            <p>
              10. Contact Us: If you have any questions about this Privacy Policy, please{' '}
                <Link to="/contactForm" style={{ color: 'blue', textDecoration: 'none' }}>
                  contact us!
                </Link>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;