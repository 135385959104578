import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Card, Button, Row, Col, Spinner, Carousel, Alert } from 'react-bootstrap';
import { 
  ArrowLeft, 
  Globe, 
  Building2, 
  Briefcase, 
  Image as ImageIcon, 
  MapPin,
  ExternalLink 
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { AppContext } from '../../AppContext';
import useAPIService from '../APIService';

const ManufacturerTemplate = () => {
  const { manufacturerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDark } = useContext(AppContext);
  const [manufacturerData, setManufacturerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoadErrors, setImageLoadErrors] = useState({});
  const apiService = useAPIService();
  const fetchingRef = useRef(false);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    const fetchManufacturerData = async () => {
      if (fetchingRef.current) return;
      fetchingRef.current = true;
  
      try {
        setIsLoading(true);
        const data = await apiService.GetManufacturerDetails(manufacturerId);
        setManufacturerData(data);
        setError(null);
      } catch (err) {
        console.error("Error fetching manufacturer data:", err);
        setError("Failed to fetch manufacturer data. Please try again later.");
      } finally {
        setIsLoading(false);
        fetchingRef.current = false;
      }
    };
  
    fetchManufacturerData();
  
    return () => {
      fetchingRef.current = false;
    };
  }, [manufacturerId, apiService]);

  const handleBack = () => {
    if (location.state?.from === 'results') {
      navigate('/manufacturer-results', {
        state: {
          from: location.state?.originalSource,
          submissionUpdated: location.state?.submissionUpdated 
        }
      });
    } else if (location.state?.from === 'list') {
      navigate('/manufacturers', {
        state: {
          previousPage: location.state.previousPage,
          previousSearch: location.state.previousSearch
        }
      });
    } else if (location.state?.from === 'search') {
      navigate('/manufacturers', {
        state: {
          fromSearch: true,
          previousPage: location.state.previousPage,
          previousSearch: location.state.previousSearch
        }
      });
    } else {
      navigate('/manufacturers');
    }
  };

  const handleImageError = (id) => {
    setImageLoadErrors(prev => ({
      ...prev,
      [id]: true
    }));
  };

  const handleWebsiteClick = (url) => {
    if (!url) return;
    const properUrl = url.startsWith('http') ? url : `https://${url}`;
    window.open(properUrl, '_blank', 'noopener noreferrer');
  };

  const styles = {
    container: {
      backgroundColor: isDark ? '#1a1a1a' : '#ffffff',
      minHeight: '100vh',
      paddingTop: window.innerWidth < 768 ? '1rem' : '3rem',
      paddingBottom: window.innerWidth < 768 ? '1rem' : '3rem'
    },
    mainCard: {
      backgroundColor: isDark ? '#262626' : '#ffffff',
      borderRadius: window.innerWidth < 768 ? '12px' : '16px',
      border: 'none',
      boxShadow: isDark 
        ? '0 8px 32px rgba(0, 0, 0, 0.5)' 
        : '0 8px 32px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden'
    },
    headerContainer: {
      position: 'relative',
      background: isDark 
        ? 'linear-gradient(135deg, #2d2d2d 0%, #1a1a1a 100%)' 
        : 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
      borderBottom: isDark 
        ? '1px solid rgba(255,255,255,0.1)' 
        : '1px solid rgba(0,0,0,0.1)'
    },
    headerContent: {
      paddingTop: window.innerWidth < 768 ? '4rem' : '5rem',
      paddingBottom: window.innerWidth < 768 ? '1.5rem' : '2rem',
      paddingLeft: window.innerWidth < 768 ? '1.5rem' : '2rem',
      paddingRight: window.innerWidth < 768 ? '1.5rem' : '2rem',
    },
    backButton: {
      position: 'absolute',
      left: window.innerWidth < 768 ? '1rem' : '1.5rem',
      top: window.innerWidth < 768 ? '1rem' : '1.5rem',
      backgroundColor: isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
      color: isDark ? '#ffffff' : '#000000',
      border: 'none',
      borderRadius: '8px',
      padding: '0.5rem 0.75rem',
      fontSize: '0.9rem',
      fontWeight: '500',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.5rem',
      transition: 'all 0.2s ease',
      zIndex: 10,
      '&:hover': {
        backgroundColor: isDark ? 'rgba(255,255,255,0.15)' : 'rgba(0,0,0,0.1)',
      }
    },
    title: {
      color: isDark ? '#ffffff' : '#000000',
      fontSize: window.innerWidth < 768 ? '1.75rem' : '2.5rem',
      fontWeight: '700',
      marginBottom: '0.75rem',
      letterSpacing: '-0.5px',
      wordBreak: 'break-word'
    },
    location: {
      color: isDark ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: window.innerWidth < 768 ? '1rem' : '1.1rem',
      flexWrap: 'wrap'
    },
    sectionTitle: {
      color: isDark ? '#ffffff' : '#000000',
      fontSize: window.innerWidth < 768 ? '1.25rem' : '1.5rem',
      fontWeight: '600',
      marginBottom: window.innerWidth < 768 ? '1rem' : '1.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem'
    },
    description: {
      color: isDark ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)',
      fontSize: window.innerWidth < 768 ? '1rem' : '1.1rem',
      lineHeight: '1.8',
      marginBottom: window.innerWidth < 768 ? '1.5rem' : '2rem',
      wordBreak: 'break-word'
    },
    specialtyBadge: {
      backgroundColor: isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
      color: isDark ? '#ffffff' : '#000000',
      padding: window.innerWidth < 768 ? '0.5rem 0.75rem' : '0.75rem 1rem',
      borderRadius: '8px',
      margin: '0.25rem',
      display: 'inline-block',
      fontSize: window.innerWidth < 768 ? '0.875rem' : '0.9rem',
      transition: 'all 0.2s ease'
    },
    websiteSection: {
      backgroundColor: isDark ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
      borderRadius: '12px',
      padding: window.innerWidth < 768 ? '1rem' : '1.5rem',
      marginBottom: window.innerWidth < 768 ? '1.5rem' : '2rem'
    },
    imageContainer: {
      position: 'relative',
      height: window.innerWidth < 768 ? '250px' : '400px',
      backgroundColor: isDark ? '#333333' : '#f8f9fa',
      borderRadius: '12px'
    },
    websiteButton: {
      width: '100%',
      backgroundColor: isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
      color: isDark ? '#ffffff' : '#000000',
      border: 'none',
      borderRadius: '8px',
      padding: '0.75rem 1rem',
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      transition: 'all 0.2s ease',
      cursor: 'pointer',
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed'
      }
    }
  };

  const renderPortfolioImages = () => {
    if (!manufacturerData?.portfolio || manufacturerData.portfolio.length === 0) {
      return (
        <div className="text-center p-4" style={{ backgroundColor: isDark ? '#333333' : '#f8f9fa', borderRadius: '12px' }}>
          <ImageIcon size={window.innerWidth < 768 ? 32 : 48} color={isDark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'} />
          <p className="mt-2" style={{ color: isDark ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)' }}>
            No portfolio images available
          </p>
        </div>
      );
    }

    return (
      <Carousel 
        interval={4000}
        indicators={true}
        controls={manufacturerData.portfolio.length > 1}
        className="portfolio-carousel"
        style={styles.carousel}
        pause="hover"
      >
        {manufacturerData.portfolio.map((item, index) => (
          <Carousel.Item key={item.id}>
            {imageLoadErrors[item.id] ? (
              <div 
                className="d-flex flex-column justify-content-center align-items-center"
                style={styles.imageContainer}
              >
                <ImageIcon size={window.innerWidth < 768 ? 48 : 64} color={isDark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'} />
                <p className="mt-2" style={{ color: isDark ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)' }}>
                  Image failed to load
                </p>
              </div>
            ) : (
              <div style={styles.imageContainer}>
                <img
                  className="w-100 h-100"
                  src={`data:image/jpeg;base64,${item.image}`}
                  alt={`${manufacturerData.name} Portfolio ${index + 1}`}
                  onError={() => handleImageError(item.id)}
                  style={{ 
                    objectFit: 'cover',
                    borderRadius: '12px'
                  }}
                  loading="lazy"
                />
                <div className="image-counter">
                  {index + 1} / {manufacturerData.portfolio.length}
                </div>
              </div>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  if (isLoading || !manufacturerData) {
    return (
      <Container className="d-flex justify-content-center align-items-center min-vh-100 py-4">
        <Spinner animation="border" variant={isDark ? 'light' : 'dark'} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-4">
        <Alert 
          variant="danger" 
          className="mobile-alert"
          style={{ 
            backgroundColor: isDark ? '#350d0d' : undefined,
            borderRadius: '12px'
          }}
        >
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <div style={styles.container} className="manufacturer-template">
      <Container className="px-3 px-sm-4">
        <AnimatePresence mode="wait">
          <motion.div
            key={manufacturerId}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <Card style={styles.mainCard}>
              <div style={styles.headerContainer}>
                <Button
                  onClick={handleBack}
                  style={styles.backButton}
                  className="back-button"
                >
                  <ArrowLeft size={16} />
                  <span>Back</span>
                </Button>
                
                <div style={styles.headerContent}>
                  <motion.h1 
                    style={styles.title}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    {manufacturerData.name}
                  </motion.h1>
                  <motion.div 
                    style={styles.location}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                  >
                    <MapPin size={window.innerWidth < 768 ? 18 : 20} />
                    <span className="location-text">
                      {manufacturerData.location || 'Location not specified'}
                    </span>
                  </motion.div>
                </div>
              </div>

              <Card.Body className="p-3 p-sm-4">
                <Row className="g-4">
                  <Col xs={12} lg={4} className="order-1 order-lg-2">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.4 }}
                    >
                      <h2 style={styles.sectionTitle}>Portfolio</h2>
                      {renderPortfolioImages()}
                    </motion.div>
                  </Col>

                  <Col xs={12} lg={8} className="order-2 order-lg-1">
                  <motion.div 
                      className="mb-4"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5 }}
                    >
                      <h2 style={styles.sectionTitle}>
                        <Building2 size={window.innerWidth < 768 ? 20 : 24} />
                        About
                      </h2>
                      <p style={styles.description}>
                        {manufacturerData.description || 'No description available.'}
                      </p>
                    </motion.div>

                    <motion.div 
                      className="mb-4"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.6 }}
                    >
                      <h2 style={styles.sectionTitle}>
                        <Briefcase size={window.innerWidth < 768 ? 20 : 24} />
                        Specialties
                      </h2>
                      <div className="specialties-container">
                        {manufacturerData.specialties?.map((specialty, index) => (
                          <motion.span 
                            key={index} 
                            style={styles.specialtyBadge}
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ delay: 0.6 + (index * 0.1) }}
                          >
                            {specialty.trim()}
                          </motion.span>
                        ))}
                      </div>
                    </motion.div>

                    <motion.div 
                      className="mb-4"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.7 }}
                    >
                      <h2 style={styles.sectionTitle}>
                        <Globe size={window.innerWidth < 768 ? 20 : 24} />
                        Website
                      </h2>
                      <div style={styles.websiteSection}>
                        <motion.button
                          whileHover={{ scale: 1.01, backgroundColor: isDark ? '#505050' : '#e0e0e0' }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => handleWebsiteClick(manufacturerData.website)}
                          style={styles.websiteButton}
                          disabled={!manufacturerData.website}
                          className="website-button"
                        >
                          <Globe size={window.innerWidth < 768 ? 18 : 20} />
                          <span style={{ flex: 1 }}>
                            {manufacturerData.website || 'Website not available'}
                          </span>
                          {manufacturerData.website && <ExternalLink size={window.innerWidth < 768 ? 14 : 16} />}
                        </motion.button>
                      </div>
                    </motion.div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </motion.div>
        </AnimatePresence>
      </Container>
    </div>
  );
};

export default React.memo(ManufacturerTemplate);