import React, { useState, useContext } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import { Send, Key, ArrowLeft, Eye, EyeOff, Check } from 'lucide-react';
import { AppContext } from '../../AppContext';
import BackgroundCollage from '../../images/collages/Results_Collage.jpg';

function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // 1: Enter email, 2: Enter code and new password
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const { isDark } = useContext(AppContext);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      await resetPassword({ username: email });
      setSuccess('Verification code has been sent to your email.');
      setStep(2);
    } catch (err) {
      setError(err.message || 'An error occurred during password reset initiation');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmResetPassword = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);

    try {
      await confirmResetPassword({ username: email, confirmationCode: code, newPassword });
      setSuccess('Your password has been successfully reset. You can now log in with your new password.');
      setTimeout(() => navigate('/login'), 5000);
    } catch (err) {
      if (err.name === 'InvalidPasswordException') {
        if (err.message.includes('Password not long enough')) {
          setError('Your password must be at least 8 characters long and contain uppercase, lowercase, numbers, and special characters.');
        } else if (err.message.includes('Password cannot be the same as previously used password')) {
          setError('You cannot reuse a previous password. Please choose a new password.');
        } else {
          setError(err.message);
        }
      } else {
        setError(err.message || 'An error occurred during password reset confirmation');
      }
    } finally {
      setLoading(false);
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${BackgroundCollage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
  };

  const cardStyle = {
    backgroundColor: isDark ? '#212121' : 'rgba(255, 255, 255, 1)',
    padding: '2rem 2rem 2.5rem 2rem',
    borderRadius: '20px',
    border: isDark ? '3px solid #ffffff' : '3px solid #000000',
    boxShadow: isDark 
      ? '0 0 20px rgba(255, 255, 255, 0.1)' 
      : '0 0 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '100%',
  };

  return (
    <div className={`password-recovery-page ${isDark ? 'dark-mode' : ''}`} style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={backgroundStyle}></div>
      <Container className="h-100 d-flex align-items-center justify-content-center py-5">
        <div style={cardStyle}>
          <h1 className="text-center mb-5" style={{ fontSize: '2.5rem', fontWeight: '700', color: isDark ? '#ffffff' : '#000000' }}>
            Reset Password
          </h1>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          {success && <Alert variant="success" className="mb-4">{success}</Alert>}
        
          <Form onSubmit={step === 1 ? handleResetPassword : handleConfirmResetPassword}>
            {step === 1 ? (
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                />
              </Form.Group>
            ) : (
              <>
                <Form.Group className="mb-4" controlId="formBasicCode">
                  <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Verification Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter verification code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                    style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicNewPassword">
                  <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>New Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                    />
                    <Button
                      variant="link"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                    >
                      {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </Button>
                  </div>
                  <div className="mt-2">
                    <span style={{ color: newPassword.length >= 8 ? 'green' : 'red' }}>
                      {newPassword.length >= 8 ? <Check size={20} /> : '❌'} At least 8 characters
                    </span>
                  </div>
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
                  <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                  />
                </Form.Group>
              </>
            )}
            <div className="d-grid gap-3 mt-5">
              <Button 
                variant="primary" 
                type="submit" 
                disabled={loading}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                {loading ? 'Processing...' : step === 1 ? 'Send Reset Code' : 'Reset Password'}
                {step === 1 ? <Send className="ms-2" size={20} /> : <Key className="ms-2" size={20} />}
              </Button>
            </div>
          </Form>
        
          <div className="mt-5 text-center">
            <Link to="/login" className="text-decoration-none d-inline-flex align-items-center" style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>
              <ArrowLeft size={20} className="me-2" />
              Back to Login
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PasswordRecovery;