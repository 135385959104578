import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Form, Button, ButtonGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Tags, X, Loader2 } from 'lucide-react';
import debounce from 'lodash/debounce';
import useAPIService from '../APIService';
import { cacheService } from '../../cacheService';
import './ManuSearch.css';

const FILTER_TYPES = {
  NAME: 'name',
  LOCATION: 'location',
  SPECIALTY: 'specialty'
};

const ManufacturerSearch = ({ isDark, onSearch, initialValue = '' }) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeFilter, setActiveFilter] = useState(FILTER_TYPES.NAME);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const apiService = useAPIService();

  const fetchSuggestions = useCallback(async (query) => {
    if (!query?.trim()) {
      setSuggestions([]);
      setShowDropdown(false);
      return;
    }

    const normalizedQuery = query.toLowerCase().trim();
    
    // Check cache first
    const cachedSuggestions = cacheService.getSuggestions(normalizedQuery, activeFilter);
    if (cachedSuggestions) {
      setSuggestions(cachedSuggestions);
      setShowDropdown(true);
      return;
    }

    setIsLoading(true);
    try {
      const response = await apiService.GetManufacturerSuggestions(
        normalizedQuery, 
        10, 
        activeFilter
      );
      
      // Cache the suggestions
      cacheService.setSuggestions(normalizedQuery, activeFilter, response.suggestions);
      
      setSuggestions(response.suggestions);
      setShowDropdown(true);
    } catch (err) {
      console.error("Error fetching suggestions:", err);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  }, [apiService, activeFilter]);

  const debouncedFetchSuggestions = useMemo(
    () => debounce((query) => fetchSuggestions(query), 150),
    [fetchSuggestions]
  );

  const handleSearchSubmit = useCallback((e) => {
    e.preventDefault();
    if (onSearch && searchQuery.trim()) {
      onSearch(searchQuery.trim());
    }
  }, [onSearch, searchQuery]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    if (query.trim()) {
      debouncedFetchSuggestions(query);
    } else {
      setSuggestions([]);
      setShowDropdown(false);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    setShowDropdown(false);
    if (onSearch) {
      onSearch('');
    }
  };

  const handleSuggestionSelect = useCallback((suggestion) => {
    setSearchQuery('');
    setShowDropdown(false);
    navigate(`/manufacturer/${encodeURIComponent(suggestion.name)}`, { 
      state: { 
        from: 'search',
        filterType: activeFilter,
        location: suggestion.location,
        specialties: suggestion.specialties
      } 
    });
  }, [navigate, activeFilter]);

  const handleFilterChange = (filterType) => {
    if (filterType === activeFilter) return;
    setActiveFilter(filterType);
    setSearchQuery('');
    setSuggestions([]);
    setShowDropdown(false);
    cacheService.invalidateSearchCache();
  };

  const getPlaceholderText = () => {
    switch (activeFilter) {
      case FILTER_TYPES.LOCATION:
        return 'Search by location...';
      case FILTER_TYPES.SPECIALTY:
        return 'Search by specialty...';
      default:
        return 'Search manufacturers...';
    }
  };

  const getFilterButtonClass = (filterType) => {
    const isActive = activeFilter === filterType;
    const baseClasses = 'transition-all duration-200 border-0 ';
    
    if (isActive) {
      return baseClasses + `${isDark 
        ? 'bg-light text-dark hover:bg-light/90' 
        : 'bg-dark text-light hover:bg-dark/90'
      }`;
    }
    
    return baseClasses + `${isDark 
      ? 'bg-dark text-light hover:bg-dark/80' 
      : 'bg-light text-dark hover:bg-light/80'
    }`;
  };

  return (
    <div className="manufacturer-search-container mb-4 position-relative">
      <div className="mx-auto max-w-2xl">
        <ButtonGroup className="w-100 mb-3 shadow-sm rounded-3 overflow-hidden">
          <Button
            onClick={() => handleFilterChange(FILTER_TYPES.NAME)}
            className={getFilterButtonClass(FILTER_TYPES.NAME)}
          >
            <div className="d-flex align-items-center justify-content-center gap-2 py-1">
              <Search size={18} />
              <span className="d-none d-sm-inline">Name</span>
            </div>
          </Button>
          <Button
            onClick={() => handleFilterChange(FILTER_TYPES.LOCATION)}
            className={getFilterButtonClass(FILTER_TYPES.LOCATION)}
          >
            <div className="d-flex align-items-center justify-content-center gap-2 py-1">
              <MapPin size={18} />
              <span className="d-none d-sm-inline">Location</span>
            </div>
          </Button>
          <Button
            onClick={() => handleFilterChange(FILTER_TYPES.SPECIALTY)}
            className={getFilterButtonClass(FILTER_TYPES.SPECIALTY)}
          >
            <div className="d-flex align-items-center justify-content-center gap-2 py-1">
              <Tags size={18} />
              <span className="d-none d-sm-inline">Specialty</span>
            </div>
          </Button>
        </ButtonGroup>

        <div className={`
          position-relative shadow-sm rounded-pill 
          ${isDark ? 'bg-dark' : 'bg-light'}
          ${isFocused ? (isDark ? 'shadow-light/10' : 'shadow-dark/10') : ''}
          transition-all duration-200
        `}>
          <Form onSubmit={handleSearchSubmit}>
            <div className="position-absolute d-flex align-items-center h-100" 
                 style={{ left: '1rem' }}>
              {isLoading ? (
                <Loader2 size={18} className="animate-spin opacity-50" />
              ) : (
                <Search size={18} className="opacity-50" />
              )}
            </div>
            
            <Form.Control
              type="text"
              placeholder={getPlaceholderText()}
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={`
                border-0 rounded-pill
                ${isDark ? 'bg-dark text-light' : 'bg-light text-dark'}
              `}
              style={{ 
                padding: '0.875rem 3rem',
                fontSize: '1rem',
              }}
              autoComplete="off"
            />

            {searchQuery && (
              <Button
                variant="link"
                onClick={handleClearSearch}
                className={`
                  position-absolute d-flex align-items-center h-100 
                  border-0 p-0
                  ${isDark ? 'text-light' : 'text-dark'}
                `}
                style={{ right: '1rem', top: '0' }}
              >
                <X size={18} className="opacity-50 hover:opacity-100 transition-opacity" />
              </Button>
            )}
          </Form>
        </div>

        {showDropdown && suggestions.length > 0 && (
          <div className={`
            position-absolute w-100 mt-2 rounded-3 overflow-hidden
            shadow-lg border
            ${isDark ? 'bg-dark border-light/10' : 'bg-light border-dark/10'}
          `}>
            <div className="py-2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.id}
                  onClick={() => handleSuggestionSelect(suggestion)}
                  className={`
                    px-3 py-2 cursor-pointer
                    ${isDark ? 'hover:bg-light/10' : 'hover:bg-dark/10'}
                    transition-colors duration-150
                  `}
                >
                  <div className="d-flex align-items-center">
                    {activeFilter === FILTER_TYPES.LOCATION && (
                      <MapPin size={16} className="me-2 flex-shrink-0" />
                    )}
                    {activeFilter === FILTER_TYPES.SPECIALTY && (
                      <Tags size={16} className="me-2 flex-shrink-0" />
                    )}
                    {activeFilter === FILTER_TYPES.NAME && (
                      <Search size={16} className="me-2 flex-shrink-0" />
                    )}
                    <div className="min-w-0">
                      <div className="text-truncate">{suggestion.name}</div>
                      {activeFilter !== FILTER_TYPES.NAME && (
                        <small className={`${isDark ? 'text-light-50' : 'text-muted'} text-truncate d-block`}>
                          {activeFilter === FILTER_TYPES.LOCATION 
                            ? suggestion.location 
                            : suggestion.specialties?.join(', ')}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManufacturerSearch;