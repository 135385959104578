import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';

const DuplicateDialog = ({ 
  isOpen,
  details,
  onCancel,
  onConfirm,
  isDark,
}) => {
  const navigate = useNavigate();
  const { setResults, setUserSubmittedImage, setUserSubmittedPrompt } = useContext(AppContext);

  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1001,
  };

  const modalContentStyle = {
    backgroundColor: isDark ? '#2D2D2D' : '#FFFFFF',
    padding: '2rem',
    borderRadius: '12px',
    width: '90%',
    maxWidth: '500px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: isDark ? '2px solid #404040' : '2px solid #E5E5E5',
    color: isDark ? '#FFFFFF' : '#000000',
  };

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: isDark ? '#FFFFFF' : '#000000',
  };

  const textStyle = {
    fontSize: '1rem',
    marginBottom: '1rem',
    color: isDark ? '#E0E0E0' : '#4A4A4A',
    lineHeight: '1.5',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '2rem',
  };

  const buttonBaseStyle = {
    padding: '0.75rem 1.5rem',
    borderRadius: '6px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    border: 'none',
  };

  const cancelButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: isDark ? '#404040' : '#E5E5E5',
    color: isDark ? '#FFFFFF' : '#000000',
  };

  const confirmButtonStyle = {
    ...buttonBaseStyle,
    backgroundColor: isDark ? '#FFFFFF' : '#000000',
    color: isDark ? '#000000' : '#FFFFFF',
  };

  const linkStyle = {
    color: isDark ? '#90CAF9' : '#1976D2',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '1rem',
    display: 'inline-block',
    transition: 'color 0.2s ease',
  };

  const handleViewResults = () => {
    if (details) {
      setResults(details.matched_manufacturers);
      setUserSubmittedImage(details.user_image);
      setUserSubmittedPrompt(details.previous_prompt);
  
      navigate('/manufacturer-results', {
        state: {
          from: 'duplicate',  
          submissionDate: details.timestamp
        }
      });
    }
  };

  if (!isOpen || !details) return null;

  return (
    <div className="duplicate-dialog-overlay" style={modalOverlayStyle}>
      <div className="duplicate-dialog-content" style={modalContentStyle}>
        <h2 style={titleStyle}>Duplicate Image Detected</h2>
        <p style={textStyle}>
          You have already submitted this image on{' '}
          {new Date(details.timestamp).toLocaleDateString()}
        </p>
        <p style={textStyle}>
          <strong>Previous description:</strong><br />
          {details.previous_prompt}
        </p>
        <div style={textStyle}>
          <a 
            onClick={handleViewResults} 
            style={linkStyle}
            onMouseEnter={(e) => e.target.style.color = isDark ? '#64B5F6' : '#1565C0'}
            onMouseLeave={(e) => e.target.style.color = isDark ? '#90CAF9' : '#1976D2'}
          >
            View previous results →
          </a>
        </div>
        <p style={textStyle}>
          Would you like to submit it again with a new description?
        </p>
        <div style={buttonContainerStyle}>
          <button 
            className="duplicate-dialog-button cancel"
            style={cancelButtonStyle}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button 
            className="duplicate-dialog-button confirm"
            style={confirmButtonStyle}
            onClick={onConfirm}
          >
            Submit Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateDialog;