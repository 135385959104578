import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';

const GlobalFooter = () => {
  const { isDark } = useContext(AppContext);

  const footerStyle = {
    backgroundColor: isDark ? '#212121' : '#f8f9fa',
    color: isDark ? '#ffffff' : '#212121',
    position: 'relative',
    zIndex: 10,
    width: '100%',
    padding: '1rem 0',
    marginTop: 'auto',
  };

  const linkStyle = {
    color: isDark ? '#ffffff' : '#212121',
    textDecoration: 'none',
    marginLeft: '1rem',
  };

  return (
    <footer style={footerStyle}>
      <Container>
        <Row className="align-items-center">
          <Col md={4} className="text-center text-md-start">
            <p className="mb-0">&copy; 2024 Pomu. All rights reserved.</p>
          </Col>
          <Col md={8} className="text-center text-md-end">
            <Link to="/contactform" style={linkStyle}>Contact</Link>
            <Link to="/privacy-policy" style={linkStyle}>Privacy Policy</Link>
            <Link to="/terms-of-service" style={linkStyle}>Terms of Service</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default GlobalFooter;