import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import useAPIService from './APIService';

const ContactForm = () => {
  const apiService = useAPIService();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    const formData = { name, email, message };
    try {
      await apiService.SubmitContactForm(formData);
      setStatus('success');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      setStatus('error');
      console.error('Error:', error);
    }
  };

  return (
    <Container className="py-5">
      <Row>
        <Col lg={8} className="mx-auto">
          <h1 className="mb-4 display-4 semi-bold">Contact Us</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </Form.Group>
            <Button 
              variant="primary" 
              type="submit" 
              disabled={status === 'sending'}
              className="mt-3"
            >
              {status === 'sending' ? 'Sending...' : 'Send Message'}
            </Button>
          </Form>
          {status === 'success' && (
            <Alert variant="success" className="mt-3">
              Your message has been sent successfully!
            </Alert>
          )}
          {status === 'error' && (
            <Alert variant="danger" className="mt-3">
              There was an error sending your message. Please try again later.
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;