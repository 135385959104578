import React from 'react';
import { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Github, Linkedin, User } from 'lucide-react';
import { AppContext } from '../AppContext';

// Import headshot images
import MaxImage from '../images/headshots/Max.jpg';
import QimingImage from '../images/headshots/Qiming.png';
import HarrisonImage from '../images/headshots/Harrison.jpg';
import AlekseyImage from '../images/headshots/Aleksey.jpg';
import JidnyeshImage from '../images/headshots/Jidnyesh.jpeg';
import AnaImage from '../images/headshots/Ana.png';
import VandanaImage from '../images/headshots/Vandana.png';


const AboutPage = () => {
  const { isDark } = useContext(AppContext);
  const darkModeColor = '#121212';

  const teamMembers = [
    {
      name: "Harrison Muchnic",
      role: "Founder & Machine Learning Engineer",
      bio: "As Pomu's founder, Harrison developed our revolutionary manufacturer matching system. His machine learning expertise enables the platform to analyze design specifications and intelligently pair designers with their ideal manufacturing partners.",
      image: HarrisonImage,
      links: {
        github: "https://github.com/hem9984",
        linkedin: "https://www.linkedin.com/in/harrisonmuchnic/"
      }
    },
    {
      name: "Max Ludwick",
      role: "Software Engineer",
      bio: "Max turned Pomu from concept to reality by building our entire web infrastructure from the ground up. Drawing on years of software development, he created an intuitive platform that seamlessly connects fashion designers with manufacturers while handling complex AI integrations behind the scenes.",
      image: MaxImage,
      links: {
        github: "https://github.com/Madmaxme",
        linkedin: "https://www.linkedin.com/in/maxludwick/"
      }
    },
    {
      name: "Qiming Liu",
      role: "Data Engineer",
      bio: "Qiming architected Pomu's manufacturer database and communication systems. His expertise in data engineering ensures our platform maintains accurate, up-to-date manufacturer profiles and capabilities, powering precise matching recommendations.",
      image: QimingImage,
      links: {
        github: "https://github.com/qimingliu1021",
        linkedin: "https://www.linkedin.com/in/qimingliu1382/"
      }
    },
    {
      name: "Aleksey Khvan",
      role: "Digital Marketing Coordinator",
      bio: "Previously a brand manager for luxury fashion labels, Aleksey now leads Pomu's outreach to emerging designers and manufacturers. His insider knowledge of the fashion industry helps us understand and address the real challenges faced by independent designers.",
      image: AlekseyImage,
      links: {
        github: "",
        linkedin: "https://www.linkedin.com/in/alekseykhvan/"
      }
    },
    {
      name: "Jidnyesh Jawanjal",
      role: "DevOps and Security Engineer",
      bio: "Jidnyesh built and maintains Pomu's secure cloud infrastructure. His expertise in scalable architecture ensures our platform can efficiently serve designers worldwide while maintaining the highest standards of data protection.",
      image: JidnyeshImage,
      links: {
        github: "https://github.com/Jidnyesh",
        linkedin: "https://www.linkedin.com/in/jidnyesh/"
      }
    },
    {
      name: "Anastasiia Eremina",
      role: "Visual Designer",
      bio: "Anastasiia shapes Pomu's comprehensive visual identity and interface design. Her expertise in creating intuitive visual systems ensures our platform delivers a cohesive and engaging experience, making complex manufacturer matching feel effortless for fashion designers.",
      image: AnaImage,
      links: {
        github: "",
        linkedin: "https://www.linkedin.com/in/anastasiia-eremina/"
      }
    },
    {
      name: "Vandana Rajesh",
      role: "Product Designer",
      bio: "Vandana orchestrates Pomu's end-to-end user experience and product strategy. She transforms complex technical features into seamless workflows, ensuring both designers and manufacturers can effectively navigate our platform to build successful partnerships.",
      image: VandanaImage,
      links: {
        github: "",
        linkedin: "https://www.linkedin.com/in/vandana-rajesh-7792b6213/"
      }
    }
  ];

  const sectionStyle = {
    position: 'relative',
    padding: '4rem 0',
    backgroundColor: isDark ? darkModeColor : 'rgba(255, 255, 255, 0.95)',
    minHeight: '100vh',
  };

  const headingStyle = {
    color: isDark ? 'white' : 'black',
    textAlign: 'center',
    marginBottom: '1.5rem', // Reduced from 3rem to 1.5rem
    borderBottom: `4px solid ${isDark ? 'white' : 'black'}`,
    paddingBottom: '0.5rem',
    display: 'inline-block',
    position: 'relative',
    fontSize: '3.5rem',
    fontWeight: 'bold',
  };

  const teamMemberCard = {
    backgroundColor: isDark ? '#2a2a2a' : '#f8f9fa',
    border: `3px solid ${isDark ? 'white' : 'black'}`,
    borderRadius: '20px',
    padding: '2.5rem',
    height: '100%',
    transition: 'all 0.3s ease',
    boxShadow: isDark 
      ? '0 4px 20px rgba(255, 255, 255, 0.1)' 
      : '0 4px 20px rgba(0, 0, 0, 0.1)',
  };

  const imageStyle = {
    width: '220px',
    height: '220px',
    borderRadius: '50%',
    objectFit: 'cover',
    border: `5px solid ${isDark ? 'white' : 'black'}`,
    marginBottom: '2rem',
    boxShadow: isDark 
      ? '0 4px 20px rgba(255, 255, 255, 0.2)' 
      : '0 4px 20px rgba(0, 0, 0, 0.2)',
  };

  const placeholderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  };

  const placeholderImageStyle = {
    width: '220px',
    height: '220px',
    borderRadius: '50%',
    border: `5px solid ${isDark ? 'white' : 'black'}`,
    boxShadow: isDark 
      ? '0 4px 20px rgba(255, 255, 255, 0.2)' 
      : '0 4px 20px rgba(0, 0, 0, 0.2)',
    backgroundColor: isDark ? '#2a2a2a' : '#f8f9fa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const socialIconStyle = {
    padding: '0.75rem',
    borderRadius: '50%',
    backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    color: isDark ? 'white' : 'black',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0.75rem',
  };

  const ProfileImage = ({ member }) => {
    if (member.image) {
      return (
        <div style={placeholderContainerStyle}>
          <motion.img
            src={member.image}
            alt={member.name}
            style={imageStyle}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          />
        </div>
      );
    }
    
    return (
      <div style={placeholderContainerStyle}>
        <motion.div
          style={placeholderImageStyle}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <User size={80} color={isDark ? 'white' : 'black'} />
        </motion.div>
      </div>
    );
  };

  const SocialLinks = ({ links }) => {
    return (
      <div className="social-links">
        {links.github && (
          <motion.a 
            href={links.github}
            target="_blank"
            rel="noopener noreferrer"
            style={socialIconStyle}
            whileHover={{ y: -3, backgroundColor: isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)' }}
          >
            <Github size={28} />
          </motion.a>
        )}
        {links.linkedin && (
          <motion.a 
            href={links.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            style={socialIconStyle}
            whileHover={{ y: -3, backgroundColor: isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)' }}
          >
            <Linkedin size={28} />
          </motion.a>
        )}
      </div>
    );
  };

  const renderTeamMembers = () => {
    const isOddCount = teamMembers.length % 2 !== 0;
    
    return (
      <Row className="gy-5 justify-content-center">
        {teamMembers.map((member, index) => {
          const isLastItem = index === teamMembers.length - 1;
          const isOddLast = isOddCount && isLastItem;
          
          return (
            <Col 
              key={index} 
              lg={isOddLast ? {span: 6, offset: 0} : 6}
              md={isOddLast ? {span: 8, offset: 0} : 6}
              className="d-flex align-items-stretch"
            >
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ 
                  transform: 'translateY(-10px)', 
                  boxShadow: isDark ? '0 6px 25px rgba(255, 255, 255, 0.15)' : '0 6px 25px rgba(0, 0, 0, 0.15)' 
                }}
                style={{ 
                  ...teamMemberCard,
                  width: '100%',
                  ...(isOddLast && {
                    maxWidth: '800px',
                    margin: '0 auto',
                  })
                }}
              >
                <div className="text-center">
                  <ProfileImage member={member} />
                  <h2 
                    style={{ 
                      color: isDark ? 'white' : 'black',
                      fontSize: isOddLast ? '2.25rem' : '2rem',
                      marginBottom: '0.75rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {member.name}
                  </h2>
                  <div
                    style={{
                      color: isDark ? '#ccc' : '#666',
                      fontSize: isOddLast ? '1.3rem' : '1.2rem',
                      marginBottom: '1.5rem',
                    }}
                  >
                    {member.role}
                  </div>
                  <p 
                    style={{ 
                      color: isDark ? 'white' : 'black',
                      fontSize: isOddLast ? '1.2rem' : '1.1rem',
                      marginBottom: '2rem',
                      lineHeight: '1.8',
                      maxWidth: isOddLast ? '600px' : 'none',
                      margin: isOddLast ? '0 auto 2rem' : '0 0 2rem',
                    }}
                  >
                    {member.bio}
                  </p>
                  <SocialLinks links={member.links} />
                </div>
              </motion.div>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <div className={isDark ? 'dark-mode' : ''}>
      <section style={sectionStyle}>
        <Container>
          <div className="text-center mb-5">
            <motion.h1
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              style={headingStyle}
              className="display-4"
            >
              Behind Pomu
            </motion.h1>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="lead"
              style={{ 
                color: isDark ? 'white' : 'black',
                maxWidth: '800px',
                margin: '1rem auto', // Reduced from 2rem to 1rem
                fontSize: '1.3rem',
                lineHeight: '1.8',
              }}
            >
              Pomu began when Harrison encountered a designer struggling to find reliable manufacturers. Recognizing the potential for innovation, he brought together our founding team of engineers and designers. Together, we built an AI-powered platform that bridges the gap between creative vision and manufacturing reality. Today, we continue to evolve this collaborative vision, making quality production accessible to designers everywhere.
            </motion.p>
          </div>
          {renderTeamMembers()}
        </Container>
      </section>
    </div>
  );
};

export default AboutPage;
