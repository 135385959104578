import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Building, MapPin, Tags } from 'lucide-react';
import { AppContext } from '../../AppContext';
import useAPIService from '../APIService';
import ManufacturerSearch from './ManufacturerSearch';
import Pagination from '../Pagination';
import { cacheService } from '../../cacheService';
import './ManuList.css';

const ManufacturerList = () => {
  const location = useLocation();
  const [manufacturers, setManufacturers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => location.state?.previousPage || 1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(() => location.state?.previousSearch || '');
  const { isDark } = useContext(AppContext);
  const navigate = useNavigate();
  const apiService = useAPIService();
  const abortControllerRef = useRef(null);
  const fetchingRef = useRef(false);
  const prefetchTimeoutRef = useRef(null);

  const prefetchAdjacentPages = useCallback(async () => {
    if (prefetchTimeoutRef.current) {
      clearTimeout(prefetchTimeoutRef.current);
    }

    prefetchTimeoutRef.current = setTimeout(async () => {
      const pagesToPrefetch = [];
      
      if (currentPage > 1) {
        pagesToPrefetch.push(currentPage - 1);
      }
      if (currentPage < totalPages) {
        pagesToPrefetch.push(currentPage + 1);
      }

      for (const page of pagesToPrefetch) {
        if (!cacheService.getListData(page, searchQuery)) {
          try {
            const response = await apiService.GetManufacturerList(page, 10, searchQuery);
            cacheService.setListData(page, searchQuery, {
              manufacturers: response.manufacturers,
              totalPages: response.total_pages,
              totalCount: response.total_count
            });
          } catch (err) {
            console.debug('Prefetch failed for page', page, err);
          }
        }
      }
    }, 300);
  }, [currentPage, totalPages, searchQuery, apiService]);

  const fetchData = useCallback(async () => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    const cachedData = cacheService.getListData(currentPage, searchQuery);
    if (cachedData) {
      setManufacturers(cachedData.manufacturers);
      setTotalPages(cachedData.totalPages);
      setTotalCount(cachedData.totalCount);
      setIsLoading(false);
      fetchingRef.current = false;
      prefetchAdjacentPages();
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await apiService.GetManufacturerList(
        currentPage, 
        10, 
        searchQuery, 
        abortControllerRef.current.signal
      );

      const responseData = {
        manufacturers: response.manufacturers,
        totalPages: response.total_pages,
        totalCount: response.total_count
      };

      cacheService.setListData(currentPage, searchQuery, responseData);

      setManufacturers(response.manufacturers);
      setTotalPages(response.total_pages);
      setTotalCount(response.total_count);
      
      prefetchAdjacentPages();
    } catch (err) {
      if (err.name === 'AbortError') return;
      console.error("Error fetching manufacturers:", err);
      setError("Failed to fetch manufacturers. Please try again later.");
    } finally {
      setIsLoading(false);
      fetchingRef.current = false;
    }
  }, [currentPage, searchQuery, apiService, prefetchAdjacentPages]);

  useEffect(() => {
    fetchData();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (prefetchTimeoutRef.current) {
        clearTimeout(prefetchTimeoutRef.current);
      }
    };
  }, [fetchData]);

  const handleManufacturerClick = useCallback((manufacturer) => {
    navigate(`/manufacturer/${encodeURIComponent(manufacturer.name)}`, { 
      state: { 
        from: 'list',
        previousPage: currentPage,
        previousSearch: searchQuery,
        manufacturerData: manufacturer
      } 
    });
  }, [navigate, currentPage, searchQuery]);

  const handleSearch = useCallback((query) => {
    if (query === searchQuery) return;
    setSearchQuery(query);
    setCurrentPage(1);
    cacheService.invalidateListCache();
  }, [searchQuery]);

  const handlePageChange = useCallback((pageNumber) => {
    if (pageNumber === currentPage) return;
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const renderManufacturerCard = useCallback(({ manufacturer }) => {
    const specialties = manufacturer.specialties || [];
    
    return (
      <motion.div 
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
        whileHover={{ scale: 1.01 }}
        onClick={() => handleManufacturerClick(manufacturer)}
        className="manufacturer-card-wrapper"
      >
        <Card 
          className={`shadow-sm h-100 ${isDark ? 'bg-dark text-light' : 'bg-light text-dark'}`}
        >
          <Card.Body>
            <div className="d-flex justify-content-between align-items-start">
              <div className="flex-grow-1">
                <div className="d-flex align-items-center mb-2">
                  <Building size={24} className="me-2 flex-shrink-0" />
                  <h5 className="mb-0 manufacturer-name">{manufacturer.name}</h5>
                </div>
                
                {manufacturer.location && (
                  <div className="d-flex align-items-center mb-2">
                    <MapPin size={16} className="me-2 flex-shrink-0" />
                    <small className="manufacturer-location">{manufacturer.location}</small>
                  </div>
                )}
                
                {specialties.length > 0 && (
                  <div className="d-flex align-items-start mt-2 specialty-container">
                    <Tags size={16} className="me-2 flex-shrink-0 mt-1" />
                    <div className="specialty-badges">
                      {specialties.slice(0, 3).map((specialty, index) => (
                        <Badge 
                          key={index}
                          bg={isDark ? "secondary" : "primary"}
                          className="specialty-badge"
                        >
                          {specialty.trim()}
                        </Badge>
                      ))}
                      {specialties.length > 3 && (
                        <Badge 
                          bg={isDark ? "secondary" : "primary"}
                          className="more-badge"
                        >
                          +{specialties.length - 3}
                        </Badge>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </motion.div>
    );
  }, [isDark, handleManufacturerClick]);

  if (error) {
    return (
      <Container className="manufacturer-container py-4">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`alert alert-danger ${isDark ? 'bg-danger text-light' : ''}`}
        >
          {error}
        </motion.div>
      </Container>
    );
  }

  return (
    <Container className="manufacturer-container py-4">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="manufacturer-content"
      >
        <h1 className={`text-center mb-4 manufacturer-title ${isDark ? 'text-light' : 'text-dark'}`}>
          Our Manufacturers
        </h1>
        
        <div className="manufacturer-search-wrapper">
          <ManufacturerSearch 
            isDark={isDark} 
            onSearch={handleSearch}
            initialValue={searchQuery}
          />
          
          {totalCount > 0 && !isLoading && (
            <p className={`text-center mt-3 mb-0 search-results ${isDark ? 'text-light' : 'text-dark'}`}>
              Found {totalCount} manufacturer{totalCount !== 1 ? 's' : ''}
              {searchQuery && ` matching "${searchQuery}"`}
            </p>
          )}
        </div>

        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center py-4"
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="manufacturer-list"
            >
              <Row className="g-3">
              {manufacturers.map((manufacturer) => (
                  <Col xs={12} key={manufacturer.id}>
                    {renderManufacturerCard({ manufacturer })}
                  </Col>
                ))}
              </Row>

              {manufacturers.length === 0 && (
                <div className={`text-center py-4 ${isDark ? 'text-light' : 'text-dark'}`}>
                  <p className="mb-0">No manufacturers found{searchQuery && ` matching "${searchQuery}"`}</p>
                </div>
              )}

              {totalPages > 1 && (
                <div className="pagination-wrapper mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    isDark={isDark}
                    className="justify-content-center"
                  />
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </Container>
  );
};

export default React.memo(ManufacturerList);