import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Upload, Plus, X } from 'lucide-react';
import useAPIService from './APIService';
import Pica from 'pica';
import { AppContext } from '../AppContext';
import { cacheService } from '../cacheService';
import './styles/SketchForm.css';
import LightBackgroundCollage from '../images/collages/light_Techpack_collage.jpg';
import DarkBackgroundCollage from '../images/collages/dark_Techpack_collage.jpg';
import spinner from '../images/spinner.png';
import DuplicateDialog from './DuplicateDialog';


const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: '300px',
          height: '300px',
          animation: 'spin 2s linear infinite',
        }}
      >
        <img
          src={spinner}
          alt="Loading"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </div>
  );
};

const FileUpload = ({ id, label, multiple, onChange, files, removeFile, optional = false }) => (
  <div className="mb-4">
    <label htmlFor={id}>{label} {optional && <span className="text-muted">(Optional)</span>}</label>
    <div className="upload-area" onClick={() => document.getElementById(id).click()}>
      {files && files.length > 0 ? (
        <div className="d-flex flex-wrap">
          {files.map((file, index) => (
            <div key={index} className="position-relative m-2">
              <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} className="rounded" />
              {multiple && (
                <button className="button button-danger position-absolute top-0 end-0" onClick={() => removeFile(index)}>
                  <X size={16} />
                </button>
              )}
              <small className="d-block mt-1">{file.name} ({(file.size / 1024).toFixed(2)} KB)</small>
            </div>
          ))}
          {multiple && (
            <div className="d-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px' }}>
              <Plus size={24} />
            </div>
          )}
        </div>
      ) : (
        <div className="text-center">
          <Upload size={48} />
          <p>Click to upload {multiple ? 'files' : 'a file'}</p>
        </div>
      )}
    </div>
    <input
      id={id}
      type="file"
      accept="image/jpeg,image/png,application"
      onChange={onChange}
      style={{ display: 'none' }}
      multiple={multiple}
    />
  </div>
);

const SketchForm = () => {
  const navigate = useNavigate();
  const { 
    isDark, 
    isAuthenticated, 
    userEmail, 
    setResults, 
    setUserSubmittedImage, 
    setUserSubmittedPrompt, 
    clearResults 
  } = useContext(AppContext);
  const apiService = useAPIService();
  
  // All state declarations together
  const [step, setStep] = useState(1);
  const [clipFile, setClipFile] = useState(null);
  const [clipFileBase64, setClipFileBase64] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [duplicateDetails, setDuplicateDetails] = useState(null);
  const [pendingBase64Image, setPendingBase64Image] = useState(null);
  const [pendingFile, setPendingFile] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/home');
    }
    clearResults();
  }, [isAuthenticated, navigate, clearResults]);

  // Replace clearSubmissionsCache with cacheService
  const clearSubmissionsCache = () => {
    try {
      cacheService.invalidateSubmissionsCache();
    } catch (error) {
      // Silent fail, maintain existing behavior
    }
  };
  
  const checkForDuplicate = async (base64Image) => {
    try {
      const checkData = new FormData();
      checkData.append('image', base64Image);
      checkData.append('userEmail', userEmail);
      
      const result = await apiService.CheckDuplicateImage(checkData);
      
      if (result.is_duplicate) {
        setDuplicateDetails(result.submission_details);
        setPendingBase64Image(base64Image);
        setIsDuplicateDialogOpen(true);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error checking for duplicate:', error);
      return false;
    }
  };
  
  // Update handleClipFileChange
  const handleClipFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && ['image/jpeg', 'image/png', 'application'].includes(selectedFile.type)) {
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('File size exceeds 10MB limit.');
        return;
      }
      try {
        const resizedImage = await resizeImage(selectedFile);
        const base64Image = await convertToBase64(resizedImage);
        
        setPendingFile(selectedFile);
        
        const isDuplicate = await checkForDuplicate(base64Image);
        if (!isDuplicate) {
          setClipFile([selectedFile]);
          setClipFileBase64(base64Image);
          setPendingFile(null);
          setError('');
        }
      } catch (error) {
        setError(`Error processing image: ${error.message}. Please try again.`);
        setPendingFile(null);
      }
    } else {
      setClipFile(null);
      setClipFileBase64(null);
      setPendingFile(null);
      setError('Please select a valid JPEG, or PNG file for the design model.');
    }
  };
  
  // Add handlers for the dialog
  const handleDuplicateCancel = () => {
    setIsDuplicateDialogOpen(false);
    setDuplicateDetails(null);
    setPendingBase64Image(null);
    setPendingFile(null);
    setClipFile(null);
    setClipFileBase64(null);
  };
  
  const handleDuplicateConfirm = () => {
    setIsDuplicateDialogOpen(false);
    if (pendingFile && pendingBase64Image) {
      setClipFile([pendingFile]);
      setClipFileBase64(pendingBase64Image);
    }
    setDuplicateDetails(null);
    setPendingBase64Image(null);
    setPendingFile(null);
  };

  const handleSubmit = async () => {
    setError('');
    setIsLoading(true);
  
    if (!clipFileBase64) {
      setError('Please select a design model file to upload.');
      setIsLoading(false);
      return;
    }
  
    if (!prompt.trim()) {
      setError('Please enter a prompt to describe your design.');
      setIsLoading(false);
      return;
    }
  
    if (!userEmail) {
      setError('User email not found. Please try logging in again.');
      setIsLoading(false);
      return;
    }
  
    const submitData = new FormData();
    submitData.append('image', clipFileBase64);
    submitData.append('prompt', prompt);
    submitData.append('userEmail', userEmail);
  
    try {
      const response = await apiService.SubmitTechPack(submitData);
      
      if (!response) {
        throw new Error('No response received from server');
      }
  
      // Clear the submissions cache before navigating - keep exact same behavior
      clearSubmissionsCache();
  
      setIsLoading(false);
      setResults(response);
      setUserSubmittedImage(clipFileBase64);
      setUserSubmittedPrompt(prompt);
      
      // Keep exact same navigation with submissionUpdated flag
      navigate('/manufacturer-results', { 
        state: { 
          from: 'sketch',
          submissionUpdated: true
        } 
      });
    } catch (error) {
      setError('Failed to submit. Please try again.');
      setIsLoading(false);
    }
  };

  const handlePromptChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 100) {
      setPrompt(inputText);
    }
  };

  const characterCount = prompt.length;

  const backgroundStyle = {
    backgroundImage: `url(${isDark ? DarkBackgroundCollage : LightBackgroundCollage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
  };

  const cardStyle = {
    backgroundColor: isDark ? '#212121' : 'rgba(255, 255, 255, 1)',
    padding: '2rem',
    borderRadius: '20px',
    border: isDark ? '3px solid #ffffff' : '3px solid #000000',
    boxShadow: isDark 
      ? '0 0 20px rgba(255, 255, 255, 0.1)' 
      : '0 0 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '1000px',
    width: '90%',
    margin: '2rem auto',
    display: 'flex',
    flexDirection: 'column',
  };

  const contentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
  };

  const columnStyle = {
    flex: '1',
    padding: '0 1rem',
  };

  const stepBarContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  };

  const stepTextStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginRight: '1rem',
    color: isDark ? '#ffffff' : '#000000',
  };

  const progressBarStyle = {
    flex: '1',
    height: '8px',
    backgroundColor: isDark ? '#444' : '#ddd',
    borderRadius: '4px',
    overflow: 'hidden',
  };

  const progressStyle = {
    height: '100%',
    backgroundColor: isDark ? '#ffffff' : '#000000',
    width: step === 1 ? '50%' : '100%',
    transition: 'width 0.3s ease-in-out',
  };

  const textareaStyle = {
    resize: 'none',
    width: '100%',
    minHeight: '150px',
  };

  const renderStepBar = () => (
    <div style={stepBarContainerStyle}>
      <div style={stepTextStyle}>Step {step}/2</div>
      <div style={progressBarStyle}>
        <div style={progressStyle}></div>
      </div>
    </div>
  );

  const renderStep1 = () => (
    <div className="sketch-form-wrapper">
      <div className="sketch-form-content">
        <div className="text-column">
          <h1 className="sketch-form-title">Upload your image</h1>
          <p className="sketch-form-subtitle">Please upload an image in .JPEG or .PNG format of your design. This can be a photo of a prototype or a tech pack</p>
        </div>
        <div className="upload-column">
          <FileUpload
            id="clip-file-upload"
            multiple={false}
            onChange={handleClipFileChange}
            files={clipFile}
          />
        </div>
      </div>
      <div className="button-container">
        <button
          className="button button-primary"
          onClick={() => setStep(2)}
          disabled={!clipFile}
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="sketch-form-wrapper">
      <div className="sketch-form-content">
        <div className="text-column">
          <h1 className="sketch-form-title">Enter Keywords</h1>
          <p className="sketch-form-subtitle">Describe your ideal item in up to 100 characters. Include details like color, material, and brand to refine your results</p>
        </div>
        <div className="prompt-column">
          <div style={{ position: 'relative' }}>
            <textarea
              className="textarea"
              style={textareaStyle}
              value={prompt}
              onChange={handlePromptChange}
              placeholder="e.g., A-line summer dress with floral print, short sleeves, and a V-neck..."
              maxLength={100}
            />
            <div style={{
              position: 'absolute',
              right: '8px',
              bottom: '23px',
              fontSize: '0.8em',
              color: '#888'
            }}>
              {characterCount}/100
            </div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className="button button-secondary" onClick={() => setStep(1)}>Back</button>
        <button
          className="button button-primary"
          onClick={handleSubmit}
          disabled={isLoading || !prompt.trim()}
        >
          Find your look
        </button>
      </div>
    </div>
  );

  return (
    <div className={`sketch-form-container ${isDark ? 'dark-mode' : ''}`} style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={backgroundStyle}></div>
      <LoadingOverlay isLoading={isLoading} />
      <DuplicateDialog
        isOpen={isDuplicateDialogOpen}
        details={duplicateDetails}
        onCancel={handleDuplicateCancel}
        onConfirm={handleDuplicateConfirm}
        isDark={isDark}
      />
      <div style={cardStyle}>
        {renderStepBar()}
        {step === 1 ? renderStep1() : renderStep2()}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

const resizeImage = async (file) => {
  const pica = new Pica();
  
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = async () => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = 224;
        canvas.height = 224;

        await pica.resize(img, canvas, {
          filter: 'lanczos3',
          alpha: true,
        });
        resolve(canvas);
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = URL.createObjectURL(file);
  });
};

const convertToBase64 = (canvas) => {
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create blob from canvas'));
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (e) => reject(new Error(`FileReader error: ${e.target.error}`));
        reader.readAsDataURL(blob);
      }, 'image/jpeg');
    } catch (error) {
      reject(error);
    }
  });
};

export default SketchForm;