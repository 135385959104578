import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Container, Row, Col, Spinner, Button, Modal } from 'react-bootstrap';
import { AppContext } from '../AppContext';
import useAPIService from './APIService';
import { format } from 'date-fns';
import { cacheService } from '../cacheService';
import { PenLine, Trash2 } from 'lucide-react';

const LoadingSpinner = ({ isDark }) => (
  <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '60vh' }}>
    <div className="text-center">
      <Spinner animation="border" role="status" className="mb-2">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p className={`mb-0 ${isDark ? 'text-light' : ''}`}>Loading your designs...</p>
    </div>
  </Container>
);

const EmptyState = ({ isDark, onCreateNew }) => (
  <div className="text-center mt-5" style={{ maxWidth: '500px', margin: '0 auto' }}>
    <p className={`fs-5 mb-4 ${isDark ? 'text-light' : ''}`}>
      No submissions found. Start by uploading a design!
    </p>
    <Button 
      variant={isDark ? 'outline-light' : 'outline-dark'} 
      size="lg"
      onClick={onCreateNew}
      className="px-4 py-2 position-relative shadow-sm d-inline-flex align-items-center"
      style={{
        transition: 'all 0.2s ease-in-out',
        borderWidth: '2px'
      }}
    >
      <PenLine className="me-2" size={20} />
      Create New Design
      <div 
        className="position-absolute w-100 h-100 top-0 start-0" 
        style={{ 
          background: isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)', 
          borderRadius: 'inherit',
          opacity: 0,
          transition: 'opacity 0.2s ease-in-out'
        }}
      />
    </Button>
  </div>
);

const DeleteConfirmationModal = ({ show, onHide, onConfirm, isDark, isDeleting }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header 
      closeButton 
      className={isDark ? 'bg-dark text-light' : ''}
      style={{ borderBottom: `1px solid ${isDark ? '#444' : '#dee2e6'}` }}
    >
      <Modal.Title>Delete Submission</Modal.Title>
    </Modal.Header>
    <Modal.Body className={isDark ? 'bg-dark text-light' : ''}>
      Are you sure you want to delete this submission? This action cannot be undone.
    </Modal.Body>
    <Modal.Footer 
      className={isDark ? 'bg-dark text-light' : ''}
      style={{ borderTop: `1px solid ${isDark ? '#444' : '#dee2e6'}` }}
    >
      <Button 
        variant={isDark ? 'outline-light' : 'outline-dark'} 
        onClick={onHide}
        disabled={isDeleting}
      >
        Cancel
      </Button>
      <Button 
        variant="danger" 
        onClick={onConfirm}
        disabled={isDeleting}
      >
        {isDeleting ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
            Deleting...
          </>
        ) : (
          'Delete'
        )}
      </Button>
    </Modal.Footer>
  </Modal>
);

const SubmissionCard = ({ submission, isDark, onDelete, onClick }) => (
  <Card 
    className={`h-100 ${isDark ? 'bg-dark text-light' : ''} hover-effect`}
    style={{ 
      cursor: 'pointer',
      transition: 'transform 0.2s ease-in-out, border-color 0.2s ease-in-out',
      border: `2px solid ${isDark ? '#ffffff' : '#000000'}`
    }}
  >
    <div className="position-relative">
      <Card.Img 
        variant="top" 
        src={`data:image/jpeg;base64,${submission.user_image}`}
        style={{ 
          height: '200px', 
          objectFit: 'cover',
          borderBottom: `1px solid ${isDark ? '#444' : '#dee2e6'}`
        }}
        onClick={onClick}
      />
      <Button
        variant="danger"
        size="sm"
        className="position-absolute top-0 end-0 m-2"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(submission);
        }}
        style={{ zIndex: 2 }}
      >
        <Trash2 size={16} />
      </Button>
    </div>
    <Card.Body onClick={onClick}>
      <Card.Title 
        className="fs-5 mb-3"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          lineHeight: '1.3'
        }}
      >
        {submission.user_prompt}
      </Card.Title>
      <div>
        <small className={`${isDark ? 'text-light-50' : 'text-muted'}`}>
          {submission.timestamp && format(new Date(submission.timestamp), 'MMM d, yyyy')}
        </small>
      </div>
    </Card.Body>
  </Card>
);

const SubmissionHistory = () => {
  const { 
    isDark, 
    setResults, 
    setUserSubmittedImage, 
    setUserSubmittedPrompt 
  } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const apiService = useAPIService();
  const [submissions, setSubmissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasConfirmedNoSubmissions, setHasConfirmedNoSubmissions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const shouldRefreshData = useCallback(() => {
    const hasNewSubmission = location.state?.submissionUpdated;
    const { isValid } = cacheService.getSubmissions();
    return hasNewSubmission || !isValid;
  }, [location.state]);

  const fetchSubmissions = useCallback(async (forceFetch = false) => {
    try {
      setIsLoading(true);
      setHasConfirmedNoSubmissions(false);

      if (!forceFetch) {
        const { data, isValid } = cacheService.getSubmissions();
        if (isValid) {
          setSubmissions(data);
          if (!shouldRefreshData()) {
            setIsLoading(false);
            setHasConfirmedNoSubmissions(data.length === 0);
            return;
          }
        }
      }
      
      const response = await apiService.GetUserSubmissions();
      
      if (response?.submissions) {
        setSubmissions(response.submissions);
        cacheService.setSubmissions(response.submissions);
        setHasConfirmedNoSubmissions(response.submissions.length === 0);
      }
    } catch (err) {
      setSubmissions([]);
      setHasConfirmedNoSubmissions(true);
    } finally {
      setIsLoading(false);
      
      if (location.state?.submissionUpdated) {
        window.history.replaceState(
          { ...location.state, submissionUpdated: false },
          document.title
        );
      }
    }
  }, [apiService, shouldRefreshData, location.state]);

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (mounted) {
        const forceFetch = shouldRefreshData();
        await fetchSubmissions(forceFetch);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, [fetchSubmissions, shouldRefreshData]);

  const handleSubmissionSelect = (submission) => {
    setResults(submission.matched_manufacturers);
    setUserSubmittedImage(submission.user_image);
    setUserSubmittedPrompt(submission.user_prompt);
    navigate('/manufacturer-results', { 
      state: { 
        from: 'history',
        submissionDate: submission.timestamp
      } 
    });
  };

  const handleCreateNewDesign = () => {
    navigate('/sketch');
  };

  const handleDeleteClick = (submission) => {
    setSubmissionToDelete(submission);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!submissionToDelete) return;
    
    setIsDeleting(true);
    try {
      await apiService.DeleteSubmission(submissionToDelete.submission_id);
      
      // Update local state
      const updatedSubmissions = submissions.filter(
        sub => sub.submission_id !== submissionToDelete.submission_id
      );
      setSubmissions(updatedSubmissions);
      
      // Update cache
      cacheService.setSubmissions(updatedSubmissions);
      
      // Check if we should show empty state
      if (updatedSubmissions.length === 0) {
        setHasConfirmedNoSubmissions(true);
      }
    } catch (error) {
      console.error('Failed to delete submission:', error);
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setSubmissionToDelete(null);
    }
  };

  if (isLoading || submissions === null) {
    return <LoadingSpinner isDark={isDark} />;
  }

  return (
    <Container className="py-5">
      <h1 className={`mb-4 ${isDark ? 'text-light' : ''}`}>Your Previous Submissions</h1>
      
      {hasConfirmedNoSubmissions ? (
        <EmptyState isDark={isDark} onCreateNew={handleCreateNewDesign} />
      ) : (
        <Row xs={1} md={2} lg={3} className="g-4">
          {submissions.map((submission) => (
            <Col key={submission.submission_id}>
              <SubmissionCard
                submission={submission}
                isDark={isDark}
                onDelete={handleDeleteClick}
                onClick={() => handleSubmissionSelect(submission)}
              />
            </Col>
          ))}
        </Row>
      )}

      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={() => {
          if (!isDeleting) {
            setShowDeleteModal(false);
            setSubmissionToDelete(null);
          }
        }}
        onConfirm={handleDeleteConfirm}
        isDark={isDark}
        isDeleting={isDeleting}
      />

      <style>
        {`
          .hover-effect:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 15px rgba(0,0,0,0.1);
          }
          
          button:hover .position-absolute {
            opacity: 1;
          }
        `}
      </style>
    </Container>
  );
};

export default SubmissionHistory;