import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Pagination = ({ 
  currentPage, 
  totalPages, 
  onPageChange, 
  isDark,
  className 
}) => {
  const [inputValue, setInputValue] = useState(currentPage.toString());

  useEffect(() => {
    setInputValue(currentPage.toString());
  }, [currentPage]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleBlur = () => {
    let pageNumber = parseInt(inputValue);
    
    if (isNaN(pageNumber) || pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    
    setInputValue(pageNumber.toString());
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <div className={`d-flex align-items-center justify-content-center gap-3 ${className}`}>
      <Button
        variant={isDark ? "dark" : "light"}
        size="sm"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="d-flex align-items-center px-2"
      >
        <ChevronLeft size={18} />
      </Button>

      <div className="d-flex align-items-center gap-2">
        <Form.Control
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          min={1}
          max={totalPages}
          className={`py-1 px-2 text-center ${isDark ? 'bg-dark text-light' : 'bg-light text-dark'}`}
          style={{ 
            width: '70px',
            borderColor: isDark ? '#6c757d' : '#dee2e6'
          }}
        />
        <span className={isDark ? 'text-light' : 'text-dark'}>
          of {totalPages}
        </span>
      </div>

      <Button
        variant={isDark ? "dark" : "light"}
        size="sm"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="d-flex align-items-center px-2"
      >
        <ChevronRight size={18} />
      </Button>
    </div>
  );
};

export default Pagination;