// Login.js
import React, { useState, useEffect, useContext } from 'react';
import { signIn, confirmSignUp, resendSignUpCode, signInWithRedirect } from 'aws-amplify/auth';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Form, Button, Container, Alert, Image } from 'react-bootstrap';
import { LogIn, UserPlus, Key } from 'lucide-react';
import { AppContext } from '../../AppContext';
import { cacheService } from '../../cacheService';

import googleSignInLight from '../../google/branding_guideline_sample_lt_rd_lg.svg';
import googleSignInDark from '../../google/branding_guideline_sample_dk_rd_lg.svg';
import googleSignInLightFocus from '../../google/branding_guideline_sample_dk_rd_lg.svg';
import googleSignInDarkFocus from '../../google/branding_guideline_sample_lt_rd_lg.svg';
import googleSignInLightPressed from '../../google/branding_guideline_sample_lt_rd_lg.svg';
import googleSignInDarkPressed from '../../google/branding_guideline_sample_dk_rd_lg.svg';
import BackgroundCollage from '../../images/collages/Results_Collage.jpg';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDark, updateAuthStatus } = useContext(AppContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [googleButtonState, setGoogleButtonState] = useState('normal');

  useEffect(() => {
    if (location.state?.message) {
      setError(location.state.message);
    }

    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedIn' || 
          payload.event === 'cognitoHostedUI' || 
          payload.event === 'federatedSignIn') {
        handleSuccessfulLogin();
      }
    });

    checkUser();

    return () => unsubscribe();
  }, [location]);

  const getUserEmail = async (user) => {
    let userEmail = null;
    
    // Try all possible locations for email
    if (user.signInDetails?.loginId) {
      userEmail = user.signInDetails.loginId;
    } else if (user.username && user.username.includes('@')) {
      userEmail = user.username;
    } else if (user.attributes?.email) {
      userEmail = user.attributes.email;
    }

    // If still no email, try to get it from the session
    if (!userEmail) {
      try {
        const session = await fetchAuthSession();
        const claims = session.tokens?.idToken?.payload;
        if (claims?.email) {
          userEmail = claims.email;
        }
      } catch (error) {
        console.error('Error getting email from session:', error);
      }
    }

    return userEmail;
  };

  const checkUser = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        handleSuccessfulLogin();
      }
    } catch (error) {
      // No current user
      console.debug('No current user found');
    }
  };

  const handleSuccessfulLogin = async () => {
    try {
      setIsLoading(true);
      
      const user = await getCurrentUser();
      const userEmail = await getUserEmail(user);
      
      if (!userEmail) {
        throw new Error('Unable to get user email');
      }
      
      // Ensure cache is cleared before updating auth status
      await cacheService.invalidateAllCaches();
      
      // Update auth status with email
      updateAuthStatus(true, userEmail);
      navigate('/sketch');
    } catch (error) {
      console.error('Error during login:', error);
      setError('Failed to complete login process. Please try again.');
      updateAuthStatus(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      await signIn({ username: email, password });
      await handleSuccessfulLogin();
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setIsConfirming(true);
        await resendSignUpCode({ username: email });
        setError('Account not confirmed. A new confirmation code has been sent to your email.');
      } else {
        setError('Failed to login: ' + err.message);
      }
      updateAuthStatus(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      await confirmSignUp({ username: email, confirmationCode });
      setError('Account confirmed successfully. You can now login.');
      setIsConfirming(false);
    } catch (err) {
      setError('Error confirming account: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await resendSignUpCode({ username: email });
      setError('Confirmation code resent. Please check your email.');
    } catch (err) {
      setError('Error resending code: ' + err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setGoogleButtonState('pressed');
      await cacheService.invalidateAllCaches(); // Clear cache before federated sign in
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Google login error:', error);
      setError("Failed to initiate Google login. Please try again.");
      setGoogleButtonState('normal');
    }
  };

  const getGoogleButtonImage = () => {
    if (googleButtonState === 'focus') {
      return isDark ? googleSignInDarkFocus : googleSignInLightFocus;
    } else if (googleButtonState === 'pressed') {
      return isDark ? googleSignInDarkPressed : googleSignInLightPressed;
    } else {
      return isDark ? googleSignInDark : googleSignInLight;
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${BackgroundCollage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
  };

  const cardStyle = {
    backgroundColor: isDark ? '#212121' : 'rgba(255, 255, 255, 1)',
    padding: '2rem 2rem 2.5rem 2rem',
    borderRadius: '20px',
    border: isDark ? '3px solid #ffffff' : '3px solid #000000',
    boxShadow: isDark 
      ? '0 0 20px rgba(255, 255, 255, 0.1)' 
      : '0 0 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '500px',
    width: '100%',
  };

  return (
    <div className={`login-page ${isDark ? 'dark-mode' : ''}`} style={{ position: 'relative', minHeight: '100vh' }}>
      <div style={backgroundStyle}></div>
      <Container className="h-100 d-flex align-items-center justify-content-center py-5">
        <div style={cardStyle}>
          <h1 className="text-center mb-5" style={{ fontSize: '2.5rem', fontWeight: '700', color: isDark ? '#ffffff' : '#000000' }}>
            {isConfirming ? 'Confirm Account' : 'Login'}
          </h1>
          {error && <Alert variant="danger" className="mb-4">{error}</Alert>}
          <Form onSubmit={isConfirming ? handleConfirmSignUp : handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              />
            </Form.Group>
            {isConfirming ? (
              <Form.Group className="mb-4">
                <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Confirmation Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter confirmation code"
                  value={confirmationCode}
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  required
                  style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-4">
                <Form.Label style={{ fontSize: '1.1rem', color: isDark ? '#ffffff' : '#000000' }}>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                />
              </Form.Group>
            )}
            <div className="d-grid gap-3 mt-5">
              <Button 
                variant="primary" 
                type="submit" 
                disabled={isLoading}
                style={{ fontSize: '1.1rem', padding: '0.75rem' }}
              >
                {isLoading ? 'Processing...' : isConfirming ? 'Confirm Account' : 'Login'}
                <LogIn className="ms-2" size={20} />
              </Button>
              {!isConfirming && (
                <Button 
                  onClick={handleGoogleLogin} 
                  variant="link"
                  className="p-0"
                  onMouseEnter={() => setGoogleButtonState('focus')}
                  onMouseLeave={() => setGoogleButtonState('normal')}
                  onMouseDown={() => setGoogleButtonState('pressed')}
                  onMouseUp={() => setGoogleButtonState('focus')}
                  onTouchStart={() => setGoogleButtonState('pressed')}
                  onTouchEnd={() => setGoogleButtonState('normal')}
                >
                  <Image 
                    src={getGoogleButtonImage()}
                    alt="Sign in with Google"
                    style={{ height: '50px', width: 'auto' }}
                  />
                </Button>
              )}
              {isConfirming && (
                <Button 
                  variant="outline-primary" 
                  onClick={handleResendCode}
                  style={{ fontSize: '1.1rem', padding: '0.75rem' }}
                >
                  Resend Code
                </Button>
              )}
            </div>
          </Form>

          <div className="mt-5 text-center" style={{ fontSize: '1.1rem' }}>
            <Link to="/create-account" className="me-4 text-decoration-none" style={{ color: isDark ? '#ffffff' : '#000000' }}>
              Create Account <UserPlus size={20} className="ms-1" />
            </Link>
            <Link to="/password-recovery" className="text-decoration-none" style={{ color: isDark ? '#ffffff' : '#000000' }}>
              Forgot Password? <Key size={20} className="ms-1" />
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Login;